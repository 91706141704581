import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Box,
  Link as MuiLink,
  useTheme,
  useMediaQuery,
  Grid,
  alpha
} from '@mui/material';
import toast from 'react-hot-toast';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      await login(email, password);
      navigate('/upload');  
      toast.success(t('auth.login_success'));
    } catch (error) {
      toast.error(t('auth.login_error'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        background: 'linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* Decorative circles */}
      <Box
        sx={{
          position: 'absolute',
          width: '300px',
          height: '300px',
          borderRadius: '50%',
          background: alpha('#fff', 0.1),
          top: '-100px',
          right: '-100px',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          width: '200px',
          height: '200px',
          borderRadius: '50%',
          background: alpha('#fff', 0.1),
          bottom: '-50px',
          left: '-50px',
        }}
      />
      
      <Container maxWidth="lg" sx={{ my: 'auto' }}>
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          {/* Brand section */}
          {!isMobile && (
            <Grid item xs={12} md={6}>
              <Box sx={{ color: 'white', pr: 4 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                  <AutoStoriesIcon sx={{ fontSize: 48, mr: 2 }} />
                  <Typography variant="h3" component="h1" sx={{ fontWeight: 700 }}>
                    LRNIO
                  </Typography>
                </Box>
                <Typography variant="h4" sx={{ mb: 2, fontWeight: 600 }}>
                  Your Personal Learning Companion
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '1.1rem', opacity: 0.9 }}>
                  Transform your learning experience with AI-powered assistance. Upload your materials and let LRNIO guide you through effective learning sessions.
                </Typography>
              </Box>
            </Grid>
          )}

          {/* Login form section */}
          <Grid item xs={12} md={6}>
            <Paper 
              elevation={24}
              sx={{
                p: 4,
                width: '100%',
                maxWidth: 450,
                mx: 'auto',
                borderRadius: 2,
                background: 'rgba(255, 255, 255, 0.95)',
                backdropFilter: 'blur(10px)',
              }}
            >
              {isMobile && (
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 4, justifyContent: 'center' }}>
                  <AutoStoriesIcon sx={{ fontSize: 32, mr: 1, color: 'primary.main' }} />
                  <Typography variant="h4" component="h1" sx={{ fontWeight: 700, color: 'primary.main' }}>
                    LRNIO
                  </Typography>
                </Box>
              )}
              
              <Typography 
                component="h2" 
                variant="h5" 
                sx={{ 
                  mb: 3, 
                  fontWeight: 600,
                  textAlign: isMobile ? 'center' : 'left'
                }}
              >
                {t('auth.login')}
              </Typography>
              
              <form onSubmit={handleSubmit}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={t('auth.email')}
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 1.5,
                    }
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={t('auth.password')}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 1.5,
                    }
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    py: 1.5,
                    borderRadius: 1.5,
                    textTransform: 'none',
                    fontSize: '1rem',
                    fontWeight: 600,
                    boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                    '&:hover': {
                      boxShadow: '0 6px 16px rgba(0,0,0,0.2)',
                    }
                  }}
                  disabled={loading}
                >
                  {loading ? t('auth.logging_in') : t('auth.login')}
                </Button>
                <Box sx={{ textAlign: 'center' }}>
                  <MuiLink 
                    component={Link} 
                    to="/register" 
                    variant="body2"
                    sx={{
                      textDecoration: 'none',
                      fontWeight: 500,
                      '&:hover': {
                        textDecoration: 'underline',
                      }
                    }}
                  >
                    {t('auth.no_account')}
                  </MuiLink>
                </Box>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Login;
