import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Container, CssBaseline, ThemeProvider } from '@mui/material';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { LearningProvider } from './contexts/LearningContext';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Chat from './components/Chat/Chat';
import Upload from './components/Upload';
import MenuBar from './components/MenuBar';
import theme from './theme';
import { Toaster } from 'react-hot-toast';
import './i18n/config';

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { currentUser } = useAuth();
  
  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }
  
  return <>{children}</>;
};

const AuthenticatedLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <MenuBar />
      <Container sx={{ mt: 3 }}>
        {children}
      </Container>
    </>
  );
};

const AuthenticatedApp = () => {
  const { currentUser } = useAuth();
  
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route
        path="/upload"
        element={
          <ProtectedRoute>
            <AuthenticatedLayout>
              <Upload />
            </AuthenticatedLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/chat"
        element={
          <ProtectedRoute>
            <Chat />
          </ProtectedRoute>
        }
      />
      <Route 
        path="/" 
        element={
          currentUser ? (
            <Navigate to="/upload" replace />
          ) : (
            <Navigate to="/login" replace />
          )
        } 
      />
    </Routes>
  );
};

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <LearningProvider>
            <div className="App">
              <AuthenticatedApp />
              <Toaster position="top-right" />
            </div>
          </LearningProvider>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
