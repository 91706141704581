import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface LearningOptionsProps {
  onOptionSelect: (option: string) => void;
}

const LearningOptions: React.FC<LearningOptionsProps> = ({ onOptionSelect }) => {
  const { t } = useTranslation();

  const options = [
    { key: 'beginLearning', text: t('chat.learningOptions.beginLearning') },
    { key: 'practice', text: t('chat.learningOptions.practice') },
    { key: 'test', text: t('chat.learningOptions.test') },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%', maxWidth: '600px', mx: 'auto' }}>
      <Typography variant="h6" sx={{ textAlign: 'center', mb: 2 }}>
        {t('chat.learningOptions.title')}
      </Typography>
      {options.map((option) => (
        <Button
          key={option.key}
          variant="outlined"
          onClick={() => onOptionSelect(option.key)}
          sx={{
            textAlign: 'left',
            whiteSpace: 'normal',
            height: 'auto',
            py: 2,
            px: 3,
          }}
        >
          {option.text}
        </Button>
      ))}
    </Box>
  );
};

export default LearningOptions;
