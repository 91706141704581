import { auth } from './firebase';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';

class ChatService {
  private currentUserId: string | null = null;

  private async makeRequest(endpoint: string, method: 'GET' | 'POST' | 'DELETE', body?: any) {
    try {
      // Check if user has changed
      const currentUser = auth.currentUser;
      if (this.currentUserId !== currentUser?.uid) {
        console.log('User changed, clearing cache and updating currentUserId');
        this.clearCache();
        this.currentUserId = currentUser?.uid || null;
      }

      const token = await auth.currentUser?.getIdToken(true); // Force token refresh
      if (!token) {
        console.error('No authentication token available');
        this.clearCache();
        throw new Error('Not authenticated');
      }

      console.log(`Making ${method} request to ${endpoint}`);
      const baseUrl = BACKEND_URL.endsWith('/') ? BACKEND_URL.slice(0, -1) : BACKEND_URL;
      const cleanEndpoint = endpoint.startsWith('/') ? endpoint.slice(1) : endpoint;
      const response = await fetch(`${baseUrl}/api/chat/${cleanEndpoint}`, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: body ? JSON.stringify(body) : undefined,
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Request failed with status ${response.status}:`, errorText);
        
        if (response.status === 401) {
          this.clearCache();
          throw new Error('Authentication expired');
        }
        if (response.status === 403) {
          throw new Error('Unauthorized access');
        }
        if (response.status === 404) {
          throw new Error('Resource not found');
        }
        if (response.status === 500) {
          throw new Error('Server error');
        }
        throw new Error(`Request failed: ${errorText || response.statusText}`);
      }

      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        console.log(`Request to ${endpoint} successful:`, data);
        return data;
      }
      return null;
    } catch (error) {
      console.error('Request failed:', error);
      this.clearCache();
      throw error;
    }
  }

  async getUserMessages() {
    return this.makeRequest('/messages', 'GET');
  }

  async saveMessage(message: any) {
    return this.makeRequest('/messages', 'POST', message);
  }

  async saveChat(chatData: any) {
    return this.makeRequest('/saved', 'POST', chatData);
  }

  async getSavedChats() {
    return this.makeRequest('/saved', 'GET');
  }

  async getChatById(chatId: string) {
    return this.makeRequest(`/saved/${chatId}`, 'GET');
  }

  async deleteChat(chatId: string) {
    return this.makeRequest(`/saved/${chatId}`, 'DELETE');
  }

  private chatCache: { data: any[] | null; timestamp: number } = {
    data: null,
    timestamp: 0
  };

  private readonly CACHE_TTL = 30000; // 30 seconds

  private clearCache() {
    console.log('Clearing chat cache');
    this.chatCache = {
      data: null,
      timestamp: 0
    };
  }
}

export default new ChatService();
