import { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { processImages } from '../services/api';
import { FileWithError, UploadState, UseImageUploadOptions, UseImageUploadResult } from '../types';

const MAX_FILE_SIZE = 3 * 1024 * 1024; // 3MB
const MAX_FILES = 10;

export function useImageUpload({ onSuccess }: UseImageUploadOptions = {}): UseImageUploadResult {
  const [state, setState] = useState<UploadState>({
    files: [],
    uploadProgress: 0,
    processingProgress: 0,
    currentProcessingFile: null,
    failedFiles: [],
    isUploading: false,
    isProcessing: false,
    error: null,
  });

  const validateFiles = useCallback((files: File[]): { valid: File[]; invalid: { name: string; reason: string }[] } => {
    const valid: File[] = [];
    const invalid: { name: string; reason: string }[] = [];

    files.forEach(file => {
      if (!file.type.includes('jpeg') && !file.type.includes('jpg')) {
        invalid.push({ name: file.name, reason: 'Only JPG files are allowed' });
      } else if (file.size > MAX_FILE_SIZE) {
        invalid.push({ name: file.name, reason: 'File size exceeds 3MB' });
      } else {
        valid.push(file);
      }
    });

    return { valid, invalid };
  }, []);

  const handleFileChange = useCallback((newFiles: File[]) => {
    const { valid, invalid } = validateFiles(newFiles);

    setState(prev => ({
      ...prev,
      files: [...prev.files, ...valid] as FileWithError[],
      failedFiles: [...prev.failedFiles, ...invalid],
      error: null,
    }));
  }, [validateFiles]);

  const handleRemove = useCallback((index: number) => {
    setState(prev => ({
      ...prev,
      files: prev.files.filter((_, i) => i !== index),
    }));
  }, []);

  const handleRetry = useCallback((index: number) => {
    const fileToRetry = state.failedFiles[index];
    setState(prev => ({
      ...prev,
      failedFiles: prev.failedFiles.filter((_, i) => i !== index),
    }));
    // Add logic to retry the file if needed
  }, [state.failedFiles]);

  const clearFailedFiles = useCallback(() => {
    setState(prev => ({
      ...prev,
      failedFiles: [],
    }));
  }, []);

  const processUploadedFiles = useCallback(async () => {
    if (state.files.length === 0) return;

    setState(prev => ({
      ...prev,
      isProcessing: true,
      processingProgress: 0,
      error: null,
    }));

    try {
      // Convert files to base64
      const processedFiles = await Promise.all(
        state.files.map(async (file) => {
          const base64Data = await new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              if (typeof reader.result === 'string') {
                // Remove data URL prefix
                const base64 = reader.result.split(',')[1];
                resolve(base64);
              } else {
                reject(new Error('Failed to read file'));
              }
            };
            reader.onerror = () => reject(reader.error);
            reader.readAsDataURL(file);
          });

          return {
            filename: file.name,
            base64Data,
          };
        })
      );

      // Process images with Claude
      const result = await processImages(processedFiles);
      
      if (onSuccess) {
        onSuccess(result.content);
      }

      setState(prev => ({
        ...prev,
        isProcessing: false,
        processingProgress: 100,
      }));

    } catch (error) {
      console.error('Error processing images:', error);
      setState(prev => ({
        ...prev,
        error: error instanceof Error ? error.message : 'Failed to process images',
        isProcessing: false,
      }));
    }
  }, [state.files, onSuccess]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileChange,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    maxFiles: MAX_FILES,
  });

  return {
    state,
    getRootProps,
    getInputProps,
    isDragActive,
    handleFileChange,
    handleRetry,
    handleRemove,
    clearFailedFiles,
    processUploadedFiles,
  };
}
