import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useImageUpload } from '../../hooks/useImageUpload';
import { useLearning } from '../../contexts/LearningContext';
import {
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Button,
  LinearProgress,
  Alert,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ErrorIcon from '@mui/icons-material/Error';

const Upload: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addMaterials } = useLearning();
  
  const {
    state: uploadState,
    getRootProps,
    getInputProps,
    isDragActive,
    handleRemove,
    clearFailedFiles,
    processUploadedFiles,
  } = useImageUpload({
    onSuccess: (extractedText) => {
      try {
        addMaterials(extractedText);
        // Small delay to ensure state is saved
        setTimeout(() => {
          navigate('/chat');
        }, 100);
      } catch (error) {
        console.error('Error adding materials:', error);
      }
    }
  });

  return (
    <Box sx={{ p: 3, maxWidth: 800, mx: 'auto' }}>
      <Paper
        {...getRootProps()}
        sx={{
          p: 3,
          mb: 3,
          border: '2px dashed',
          borderColor: isDragActive ? 'primary.main' : 'grey.300',
          backgroundColor: isDragActive ? 'action.hover' : 'background.paper',
          cursor: 'pointer',
          transition: 'all 0.2s ease',
          '&:hover': {
            borderColor: 'primary.main',
            backgroundColor: 'action.hover',
          },
        }}
      >
        <input {...getInputProps()} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main' }} />
          <Typography variant="h6" align="center">
            {isDragActive
              ? t('upload.dropHere')
              : t('upload.dragAndDrop')}
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            {t('upload.requirements')}
          </Typography>
        </Box>
      </Paper>

      {uploadState.error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {uploadState.error}
        </Alert>
      )}

      {uploadState.files.length > 0 && (
        <Paper sx={{ p: 2, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            {t('upload.selectedFiles')}
          </Typography>
          <List>
            {uploadState.files.map((file, index) => (
              <ListItem
                key={index}
                secondaryAction={
                  <IconButton
                    edge="end"
                    onClick={() => handleRemove(index)}
                    color="error"
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={file.name}
                  secondary={`${(file.size / 1024 / 1024).toFixed(2)} MB`}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}

      {uploadState.failedFiles.length > 0 && (
        <Paper sx={{ p: 2, mb: 3, backgroundColor: 'error.light' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
            <Typography variant="h6" color="error.dark">
              {t('upload.failedFiles')}
            </Typography>
            <Button
              size="small"
              onClick={clearFailedFiles}
              sx={{ color: 'error.dark' }}
            >
              {t('upload.clearFailed')}
            </Button>
          </Box>
          <List>
            {uploadState.failedFiles.map((file, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={file.name}
                  secondary={file.reason}
                  sx={{ color: 'error.dark' }}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}

      {uploadState.isProcessing && (
        <Box sx={{ mb: 3 }}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {uploadState.currentProcessingFile
              ? t('upload.processing', { file: uploadState.currentProcessingFile })
              : t('upload.preparingFiles')}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={uploadState.processingProgress}
            sx={{ mb: 1 }}
          />
          <Typography variant="body2" color="textSecondary" align="right">
            {`${Math.round(uploadState.processingProgress)}%`}
          </Typography>
        </Box>
      )}

      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          onClick={processUploadedFiles}
          disabled={uploadState.files.length === 0 || uploadState.isProcessing}
        >
          {t('upload.process')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/chat')}
          disabled={!uploadState.processingProgress || uploadState.isProcessing}
        >
          {t('upload.startChat')}
        </Button>
      </Box>
    </Box>
  );
};

export default Upload;
