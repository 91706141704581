import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import chatService from '../../services/chat';
import { 
  List, 
  ListItem, 
  ListItemText, 
  ListItemSecondaryAction, 
  IconButton, 
  Typography, 
  Drawer, 
  Box,
  Divider,
  CircularProgress
} from '@mui/material';
import { Delete as DeleteIcon, Chat as ChatIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast'; // Import toast

interface Message {
  content: string;
  sender: 'user' | 'bot';
  timestamp: number;
}

interface SavedChat {
  id: string;
  title: string;
  lastMessage: string;
  timestamp: number;
  messages?: Message[];
}

interface ChatHistoryProps {
  open: boolean;
  onClose: () => void;
  onLoadChat: (messages: Message[]) => void;
}

const ChatHistory: React.FC<ChatHistoryProps> = ({ open, onClose, onLoadChat }) => {
  const [savedChats, setSavedChats] = useState<SavedChat[]>([]);
  const [loading, setLoading] = useState(true);
  const [deletingChats, setDeletingChats] = useState<Set<string>>(new Set());
  const { currentUser } = useAuth();
  const { t } = useTranslation();

  const loadSavedChats = useCallback(async () => {
    console.log('ChatHistory: Starting to load saved chats');
    if (!currentUser) {
      console.log('ChatHistory: No current user, skipping load');
      setSavedChats([]);
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const chats = await chatService.getSavedChats();
      console.log('ChatHistory: Loaded chats:', chats);
      
      // Validate chat data
      if (!Array.isArray(chats)) {
        console.error('ChatHistory: Invalid chat data received:', chats);
        throw new Error('Invalid chat data received');
      }

      // Filter out any invalid chats
      const validChats = chats.filter(chat => 
        chat && 
        typeof chat.id === 'string' && 
        chat.id.length > 0 &&
        typeof chat.title === 'string'
      );

      setSavedChats(validChats);
    } catch (error) {
      console.error('ChatHistory: Error loading saved chats:', error);
      setSavedChats([]); // Reset to empty array on error
      if (error instanceof Error) {
        if (error.message === 'Authentication expired') {
          toast.error(t('auth.session_expired'));
        } else if (error.message === 'Unauthorized access to chat') {
          toast.error(t('chat.unauthorized'));
        } else {
          toast.error(t('chat.load_error'));
        }
      } else {
        toast.error(t('chat.load_error'));
      }
    } finally {
      setLoading(false);
    }
  }, [currentUser, t]);

  useEffect(() => {
    if (open) {
      loadSavedChats();
    }
  }, [open, loadSavedChats]);

  const handleDeleteChat = async (chatId: string) => {
    console.log('ChatHistory: Starting to delete chat:', chatId);
    if (deletingChats.has(chatId)) {
      console.log('ChatHistory: Already deleting this chat, skipping');
      return;
    }

    try {
      setDeletingChats(prev => new Set(prev).add(chatId));
      
      // First update local state for immediate feedback
      setSavedChats(chats => {
        const newChats = chats.filter(chat => chat.id !== chatId);
        console.log('ChatHistory: Updated local state, remaining chats:', newChats);
        return newChats;
      });

      // Then actually delete from server
      await chatService.deleteChat(chatId);
      console.log('ChatHistory: Successfully deleted chat');
      
      toast.success(t('chat.delete_success'));
    } catch (error) {
      console.error('ChatHistory: Error deleting chat:', error);
      // Reload chats to ensure correct state
      loadSavedChats();
      toast.error(t('chat.delete_error'));
    } finally {
      setDeletingChats(prev => {
        const newSet = new Set(prev);
        newSet.delete(chatId);
        return newSet;
      });
    }
  };

  const handleLoadChat = async (chat: SavedChat) => {
    if (!chat.id) {
      console.error('ChatHistory: Attempted to load chat without ID');
      toast.error(t('chat.load_error'));
      return;
    }

    try {
      setLoading(true);
      const fullChat = await chatService.getChatById(chat.id);
      
      if (!fullChat?.messages || !Array.isArray(fullChat.messages)) {
        throw new Error('Invalid chat data received');
      }

      onLoadChat(fullChat.messages);
      onClose();
      toast.success(t('chat.load_success'));
    } catch (error) {
      console.error('ChatHistory: Error loading chat:', error);
      toast.error(t('chat.load_error'));
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (timestamp: number) => {
    if (!timestamp) return '';
    try {
      return new Date(timestamp).toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: { xs: '100%', sm: 400 } }
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          {t('chat.history')}
        </Typography>
      </Box>
      <Divider />
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      ) : savedChats.length === 0 ? (
        <Box sx={{ p: 4, textAlign: 'center' }}>
          <Typography color="textSecondary">
            {t('chat.no_history')}
          </Typography>
        </Box>
      ) : (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {savedChats.map((chat) => (
            <React.Fragment key={chat.id}>
              <ListItem
                button
                onClick={() => handleLoadChat(chat)}
                sx={{
                  pr: 12, // Add padding on the right to accommodate icons
                }}
              >
                <ListItemText
                  primary={chat.title}
                  secondary={formatDate(chat.timestamp)}
                  sx={{
                    mr: 2, // Add margin to the right
                    '.MuiListItemText-primary': {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    },
                    '.MuiListItemText-secondary': {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }
                  }}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="load"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleLoadChat(chat);
                    }}
                    sx={{ mr: 1 }}
                  >
                    <ChatIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteChat(chat.id);
                    }}
                    disabled={deletingChats.has(chat.id)}
                  >
                    {deletingChats.has(chat.id) ? (
                      <CircularProgress size={24} />
                    ) : (
                      <DeleteIcon />
                    )}
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider component="li" />
            </React.Fragment>
          ))}
        </List>
      )}
    </Drawer>
  );
};

export default ChatHistory;
