import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useLearning } from '../../contexts/LearningContext';
import { 
  Box, 
  TextField, 
  Button, 
  Paper, 
  Typography,
  Container,
  CircularProgress,
  Grid,
  InputAdornment,
  Alert
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import llmService from '../../services/llm';
import chatService from '../../services/chat';
import ChatHistory from './ChatHistory';
import toast from 'react-hot-toast';
import MenuBar from '../MenuBar/MenuBar';
import LearningOptions from './LearningOptions';

interface Message {
  id?: string;
  content: string;
  sender: 'user' | 'bot';
  timestamp: number;
}

interface ChatState {
  messages: Message[];
  isTyping: boolean;
  error: string | null;
  historyOpen: boolean;
  showOptions: boolean;
}

export interface ChatRef {
  handleClearChat: () => void;
}

const Chat = forwardRef<ChatRef>((_, ref) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const { 
    hasUploadedMaterials,
    chatMessages,
    extractedContext,
    setChatMessages,
    clearChat: clearLearningChat
  } = useLearning();

  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState<ChatState>(() => ({
    messages: chatMessages.length > 0 ? chatMessages : [{
      content: llmService.getInitialMessage(),
      sender: 'bot',
      timestamp: Date.now()
    }],
    isTyping: false,
    error: null,
    historyOpen: false,
    showOptions: chatMessages.length === 0 && hasUploadedMaterials
  }));

  useEffect(() => {
    if (!hasUploadedMaterials) {
      navigate('/upload');
    } else if (state.messages.length === 1 && !state.showOptions) {
      // Show options when we have just the initial message and materials are uploaded
      setState(prev => ({ ...prev, showOptions: true }));
    }
  }, [hasUploadedMaterials, navigate, state.messages.length, state.showOptions]);

  useEffect(() => {
    if (extractedContext) {
      llmService.setExtractedContext(extractedContext);
    }
  }, [extractedContext]);

  useEffect(() => {
    setChatMessages(state.messages);
  }, [state.messages, setChatMessages]);

  const handleLocalClearChat = () => {
    clearLearningChat();
    setState(prev => ({
      ...prev,
      messages: [{
        content: llmService.getInitialMessage(),
        sender: 'bot',
        timestamp: Date.now()
      }],
      isTyping: false,
      error: null,
      historyOpen: false,
      showOptions: true
    }));
  };

  useImperativeHandle(ref, () => ({
    handleClearChat: handleLocalClearChat
  }));

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    }
  }, [currentUser, navigate]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [state.messages]);

  const handleSend = async (option?: string) => {
    if (!input.trim() && !option || !currentUser) return;

    const userMessage: Message = {
      content: option || input,
      sender: 'user',
      timestamp: Date.now(),
    };

    setState(prev => ({ ...prev, messages: [...prev.messages, userMessage] }));
    setInput('');
    setIsLoading(true);

    try {
      // Save user message
      await chatService.saveMessage(userMessage);

      // Get bot response
      const formattedMessages = [...state.messages, userMessage].map(msg => ({
        content: msg.content,
        role: msg.sender === 'user' ? 'user' as const : 'assistant' as const
      }));

      const response = await llmService.chat(formattedMessages);

      const botMessage: Message = {
        content: response,
        sender: 'bot',
        timestamp: Date.now(),
      };

      // Save bot message
      await chatService.saveMessage(botMessage);

      setState(prev => ({
        ...prev,
        messages: [...prev.messages, botMessage],
        error: null
      }));
    } catch (error) {
      console.error('Error in chat:', error);
      setState(prev => ({
        ...prev,
        error: error instanceof Error ? error.message : 'An error occurred'
      }));
      toast.error(t('chat.error'));
    } finally {
      setIsLoading(false);
      // Focus the input field after the bot responds
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  };

  const handleOptionSelect = async (option: string) => {
    setState(prev => ({ ...prev, showOptions: false }));
    const translatedOption = t(`chat.learningOptions.${option}`);
    try {
      await handleSend(translatedOption);
    } catch (error) {
      console.error('Error handling option:', error);
      setState(prev => ({ ...prev, error: 'Failed to process option' }));
    }
  };

  const handleSaveChat = async () => {
    if (!currentUser) return;
    
    try {
      const lastMessage = state.messages[state.messages.length - 1].content;
      const title = state.messages[0].content.substring(0, 50) + '...';
      
      const chatData = {
        title,
        lastMessage,
        timestamp: Date.now(),
        messages: state.messages
      };

      await chatService.saveChat(chatData);
      toast.success(t('chat.save_success'));
    } catch (error) {
      console.error('Error saving chat:', error);
      toast.error(t('chat.save_error'));
    }
  };

  const handleLoadChat = (messages: Message[]) => {
    setState(prev => ({
      ...prev,
      messages,
      historyOpen: false
    }));
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <>
      <MenuBar 
        onClearChat={handleLocalClearChat}
        onSaveChat={handleSaveChat}
        onLoadHistory={() => setState(prev => ({ ...prev, historyOpen: true }))}
        hasMessages={state.messages.length > 1}
      />
      <Container maxWidth="md" sx={{ height: 'calc(100vh - 64px)', py: 2 }}>
        <Paper elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
            {state.messages.map((message, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
                  mb: 2,
                }}
              >
                <Paper
                  elevation={1}
                  sx={{
                    p: 2,
                    backgroundColor: message.sender === 'user' ? 'primary.main' : 'grey.200',
                    color: message.sender === 'user' ? 'white' : 'text.primary',
                    maxWidth: '70%',
                  }}
                >
                  <Typography>{message.content}</Typography>
                </Paper>
              </Box>
            ))}
            {state.showOptions && (
              <Box sx={{ mt: 2 }}>
                <LearningOptions onOptionSelect={handleOptionSelect} />
              </Box>
            )}
            {isLoading && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
                <CircularProgress size={20} />
              </Box>
            )}
            <div ref={messagesEndRef} />
          </Box>
          <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={t('chat.placeholder')}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && !state.showOptions && handleSend()}
              disabled={isLoading || state.showOptions}
              autoComplete="off"
              inputRef={inputRef}
            />
            <Button
              variant="contained"
              onClick={() => handleSend()}
              disabled={isLoading || state.showOptions}
              sx={{ mt: 1 }}
              fullWidth
            >
              {t('chat.send')}
            </Button>
          </Box>
        </Paper>
        <ChatHistory
          open={state.historyOpen}
          onClose={() => setState(prev => ({ ...prev, historyOpen: false }))}
          onLoadChat={handleLoadChat}
        />
      </Container>
    </>
  );
});

export default Chat;
