import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Button, Box, Tooltip, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext';
import { useLearning } from '../../contexts/LearningContext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import HistoryIcon from '@mui/icons-material/History';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ChatIcon from '@mui/icons-material/Chat';

interface MenuBarProps {
  onClearChat?: () => void;
  onSaveChat?: () => void;
  onLoadHistory?: () => void;
  hasMessages?: boolean;
}

const MenuBar: React.FC<MenuBarProps> = ({ 
  onClearChat, 
  onSaveChat, 
  onLoadHistory,
  hasMessages 
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { currentUser, logout } = useAuth();
  const { hasUploadedMaterials } = useLearning();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to sign out:', error);
    }
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 0, mr: 2 }}>
          Learning Companion
        </Typography>
        <Box sx={{ flexGrow: 1, display: 'flex', gap: 2 }}>
          <Button
            color="inherit"
            startIcon={<CloudUploadIcon />}
            onClick={() => navigate('/upload')}
            sx={{
              backgroundColor: location.pathname === '/upload' ? 'rgba(255,255,255,0.1)' : 'transparent'
            }}
          >
            {t('nav.upload_file')}
          </Button>
          <Tooltip title={!hasUploadedMaterials ? t('menu.uploadMaterialsFirst') : ''}>
            <span>
              <Button
                color="inherit"
                startIcon={<ChatIcon />}
                onClick={() => navigate('/chat')}
                disabled={!hasUploadedMaterials}
                sx={{
                  backgroundColor: location.pathname === '/chat' ? 'rgba(255,255,255,0.1)' : 'transparent',
                  opacity: !hasUploadedMaterials ? 0.5 : 1
                }}
              >
                {t('nav.chat_room')}
              </Button>
            </span>
          </Tooltip>
        </Box>
        {location.pathname === '/chat' && (
          <Box sx={{ display: 'flex', gap: 1, mr: 2 }}>
            <IconButton
              color="inherit"
              onClick={onLoadHistory}
              title={t('chat.view_history')}
            >
              <HistoryIcon />
            </IconButton>
            {hasMessages && (
              <>
                <IconButton
                  color="inherit"
                  onClick={onSaveChat}
                  disabled={!hasMessages}
                  title={t('chat.save_conversation')}
                >
                  <SaveIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  onClick={onClearChat}
                  disabled={!hasMessages}
                  title={t('chat.clear_conversation')}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </Box>
        )}
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={(event) => event.currentTarget}
            color="inherit"
          >
            <AccountCircleIcon />
          </IconButton>
          <Button color="inherit" onClick={handleLogout}>
            <LogoutIcon sx={{ mr: 1 }} />
            {t('auth.logout')}
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default MenuBar;
