// Message type definition
export interface Message {
  content: string;
  role: 'user' | 'assistant' | 'system';
}

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';

class LLMService {
  private extractedContext: string | null = null;

  setExtractedContext(context: string) {
    this.extractedContext = context;
  }

  getInitialMessage(): string {
    if (this.extractedContext) {
      return 'I have reviewed your learning materials. What would you like to learn about?';
    }
    return 'Hello! I am your learning companion. How can I help you today?';
  }

  async generateResponse(messages: Message[]): Promise<string> {
    return this.chat(messages);
  }

  async chat(messages: Message[]): Promise<string> {
    try {
      const baseUrl = BACKEND_URL.endsWith('/') ? BACKEND_URL.slice(0, -1) : BACKEND_URL;
      const response = await fetch(`${baseUrl}/api/llm/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          messages,
          context: this.extractedContext 
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.content; 
    } catch (error) {
      console.error('Error in chat API call:', error);
      throw new Error('Failed to get response from chat service');
    }
  }
}

export default new LLMService();
