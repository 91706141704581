import { auth } from './firebase';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

interface ProcessImagesResponse {
  content: string;
}

export async function processImages(images: { filename: string; base64Data: string }[]): Promise<ProcessImagesResponse> {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not authenticated');
  }

  const token = await user.getIdToken();
  
  const response = await fetch(`${API_URL}/api/llm/process-images`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ images }),
  });

  if (!response.ok) {
    const error = await response.text();
    console.error('API Error:', error);
    throw new Error(`Failed to process images: ${error}`);
  }

  return response.json();
}
