import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { Message } from '../types';
import { useAuth } from './AuthContext';

interface LearningState {
  hasUploadedMaterials: boolean;
  chatMessages: Message[];
  extractedContext: string;
}

interface LearningContextType extends LearningState {
  addMaterials: (context: string) => void;
  setChatMessages: (messages: Message[]) => void;
  clearChat: () => void;
  clearAll: () => void;
}

const STORAGE_KEY_PREFIX = 'learningState';

const getInitialState = (userId: string | null): LearningState => {
  if (!userId) {
    return {
      hasUploadedMaterials: false,
      chatMessages: [],
      extractedContext: '',
    };
  }

  const savedState = localStorage.getItem(`${STORAGE_KEY_PREFIX}_${userId}`);
  if (savedState) {
    return JSON.parse(savedState);
  }
  return {
    hasUploadedMaterials: false,
    chatMessages: [],
    extractedContext: '',
  };
};

const LearningContext = createContext<LearningContextType | undefined>(undefined);

export const LearningProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { currentUser } = useAuth();
  const [state, setState] = useState<LearningState>(() => 
    getInitialState(currentUser?.uid || null)
  );

  // Reset state when user changes
  useEffect(() => {
    const newState = getInitialState(currentUser?.uid || null);
    setState(newState);
    
    // If user logs out, clear the state
    if (!currentUser?.uid) {
      localStorage.removeItem(`${STORAGE_KEY_PREFIX}_${currentUser?.uid}`);
    }
  }, [currentUser?.uid]);

  // Save state to localStorage whenever it changes
  useEffect(() => {
    if (currentUser?.uid && state.hasUploadedMaterials) {
      localStorage.setItem(
        `${STORAGE_KEY_PREFIX}_${currentUser.uid}`, 
        JSON.stringify(state)
      );
    }
  }, [state, currentUser?.uid]);

  const addMaterials = (context: string) => {
    setState(prev => ({
      ...prev,
      hasUploadedMaterials: true,
      extractedContext: prev.extractedContext 
        ? `${prev.extractedContext}\n\n${context}` 
        : context
    }));
  };

  const setChatMessages = (messages: Message[]) => {
    setState(prev => ({
      ...prev,
      chatMessages: messages
    }));
  };

  const clearChat = () => {
    setState(prev => ({
      ...prev,
      hasUploadedMaterials: false,
      chatMessages: [],
      extractedContext: ''
    }));
    if (currentUser?.uid) {
      localStorage.removeItem(`${STORAGE_KEY_PREFIX}_${currentUser.uid}`);
    }
  };

  const clearAll = () => {
    setState({
      hasUploadedMaterials: false,
      chatMessages: [],
      extractedContext: '',
    });
    if (currentUser?.uid) {
      localStorage.removeItem(`${STORAGE_KEY_PREFIX}_${currentUser.uid}`);
    }
  };

  return (
    <LearningContext.Provider 
      value={{ 
        ...state,
        addMaterials,
        setChatMessages,
        clearChat,
        clearAll
      }}
    >
      {children}
    </LearningContext.Provider>
  );
};

export const useLearning = () => {
  const context = useContext(LearningContext);
  if (context === undefined) {
    throw new Error('useLearning must be used within a LearningProvider');
  }
  return context;
};
